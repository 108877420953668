<template>
  <div class="">
    <div class="rounded">
      <div class="w-full inline-flex top-lane">
        <div
          class="h-6"
          style="width: 14rem; border-right: 1px solid #2a2d31;text-align: left;padding-left: 10px;"
        >
          <span
            style="font-weight: 500; color: #ecf6fe; font-family: fantasy; font-size: 14px;"
            ></span
          >
        </div>
        <div class="h-6" style="width: 49rem;"></div>
        <div
          class="h-6"
          style="width: 10rem; border-left: 1px solid #2a2d31;text-align: left;padding-left: 10px;"
        >
          <span
            style="font-weight: 500; color: #ecf6fe; font-family: fantasy; font-size: 14px;"
            ></span
          >
        </div>
      </div>
      <Lane
        v-for="(lane, index) in enabledLanes"
        :key="index"
        :lane="lane"
        :offset="index"
        @play-note="playNote"
        @toggle-note="toggleNote"
      />
    </div>
  </div>
</template>

<script>
import Lane from './Lane.vue';

export default {
  name: 'Grid',

  components: { Lane },

  props: {
    lanes: Array,
  },

  computed: {
    enabledLanes() {
      return this.lanes.filter(lane => lane.enabled);
    },
  },

  methods: {
    toggleNote(note) {
      this.$emit('toggle-note', note);
    },

    playNote(note) {
      this.$emit('play-note', note);
    },
  },
};
</script>
