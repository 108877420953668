<template>
  <div id="app">
    <div class="player-container">
      <div class="beats-container">
        <div class="grid-container">
          <Grid
            class="mx-auto"
            :lanes="beat.lanes"
            @toggle-note="toggleNote"
            @play-note="playNote"
          />
        </div>
      </div>
      <div class="controls-container">
        <div class="logo-left">
          <div
            class="inline-flex w-full rounded-br"
            style="align-items: center;"
          >
            <TempoControl v-model="beat.tempo" />
          </div>
        </div>
        <div class="beat-controls-container">
          <Button
            @click="togglePlayback"
            color="primary"
            class="h-10 rounded border-grey"
            v-if="playing === true"
            title="Stop"
          >
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background:new 0 0 512 512;"
              xml:space="preserve"
              fill="#ffffff"
              width="20"
            >
              <g>
                <g>
                  <path
                    d="M181.333,0H74.667c-17.643,0-32,14.357-32,32v448c0,17.643,14.357,32,32,32h106.667c17.643,0,32-14.357,32-32V32
                        C213.333,14.357,198.976,0,181.333,0z M192,480c0,5.867-4.779,10.667-10.667,10.667H74.667C68.779,490.667,64,485.867,64,480V32
                        c0-5.867,4.779-10.667,10.667-10.667h106.667c5.888,0,10.667,4.8,10.667,10.667V480z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M437.333,0H330.667c-17.643,0-32,14.357-32,32v448c0,17.643,14.357,32,32,32h106.667c17.643,0,32-14.357,32-32V32
                        C469.333,14.357,454.976,0,437.333,0z M448,480c0,5.867-4.779,10.667-10.667,10.667H330.667c-5.888,0-10.667-4.8-10.667-10.667V32
                        c0-5.867,4.779-10.667,10.667-10.667h106.667c5.888,0,10.667,4.8,10.667,10.667V480z"
                  />
                </g>
              </g>
            </svg>
          </Button>
          <Button
            @click="togglePlayback"
            color="primary"
            class="h-10 rounded border-grey"
            title="Start"
            v-else
          >
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 30.065 30.065"
              style="enable-background:new 0 0 30.065 30.065;"
              xml:space="preserve"
              fill="#ffffff"
              width="20"
            >
              <g>
                <path
                  style="fill:#ffffff;"
                  d="M26.511,12.004L6.233,0.463c-2.151-1.228-4.344,0.115-4.344,2.53v24.093
                      c0,2.046,1.332,2.979,2.57,2.979c0.583,0,1.177-0.184,1.767-0.543l20.369-12.468c1.024-0.629,1.599-1.56,1.581-2.555
                      C28.159,13.503,27.553,12.593,26.511,12.004z M25.23,14.827L4.862,27.292c-0.137,0.084-0.245,0.126-0.319,0.147
                      c-0.02-0.074-0.04-0.188-0.04-0.353V2.994c0-0.248,0.045-0.373,0.045-0.404c0.08,0.005,0.22,0.046,0.396,0.146l20.275,11.541
                      c0.25,0.143,0.324,0.267,0.348,0.24C25.554,14.551,25.469,14.678,25.23,14.827z"
                />
              </g>
            </svg>
          </Button>
          <Button
            class="h-10 ml-0 rounded border-grey"
            @click="clearPattern"
            title="Clear"
          >
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style="enable-background:new 0 0 512 512;"
              xml:space="preserve"
              fill="#ffffff"
              width="20"
            >
              <g>
                <g>
                  <path
                    d="M256,0C114.498,0,0,114.509,0,256c0,141.502,114.509,256,256,256c141.502,0,256-114.509,256-256
                        C512,114.498,397.491,0,256,0z M256,467.478C139.39,467.478,44.522,372.61,44.522,256S139.39,44.522,256,44.522
                        S467.478,139.39,467.478,256S372.61,467.478,256,467.478z"
                  />
                </g>
              </g>
            </svg>
          </Button>
        </div>
        <div class="logo-right"></div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import Tone from 'tone';

import { defaultBeat, beatFromUrl } from './modules/beat';
import { Drums } from './modules/drums';
import * as storage from './modules/storage';
import Button from './components/Button.vue';
//import CopyButton from './components/CopyButton.vue';
//import FeedbackButton from './components/FeedbackButton.vue';
import Grid from './components/Grid.vue';
//import InstrumentsControl from './components/InstrumentsControl.vue';
//import BeatLibrary from './components/BeatLibrary.vue';
import TempoControl from './components/TempoControl.vue';

export default {
  name: 'app',

  components: {
    Button,
    //CopyButton,
    //FeedbackButton,
    Grid,
    //InstrumentsControl,
    //BeatLibrary,
    TempoControl,
  },

  data() {
    return {
      loading: true,
      playing: false,
      header: 'none',
      savedBeatTitles: storage.getSavedBeatTitles(),
      beat: beatFromUrl(storage.getLastOpenBeat(defaultBeat)),
      beatUrl: '',
    };
  },

  watch: {
    beat: {
      handler(beat) {
        this.onBeatChange(beat);
      },
      deep: true,
      immediate: true,
    },
    'beat.tempo': {
      handler(newTempo) {
        Tone.Transport.bpm.value = newTempo;
      },
      immediate: true,
    },
    'beat.title': {
      handler(newTitle) {
        this.onTitleChange(newTitle);
      },
      immediate: true,
    },
  },

  created() {
    this.drums = new Drums(this.beat.lanes, this.beat.samplePack, () => {
      this.loading = false;
    });
    this.onBeatChange = debounce(this.onBeatChange, 300, {
      leading: true,
      trailing: false,
    });
    this.onTitleChange = debounce(this.onTitleChange, 300, {
      leading: true,
      trailing: true,
    });
  },

  mounted() {
    document.addEventListener(
      'click',
      () => {
        if (Tone.context.state !== 'running') {
          Tone.context.resume();
        }
      },
      { once: true }
    );
  },

  methods: {
    start() {
      this.playing = true;
      Tone.Transport.start();
      this.drums.start();
    },

    stop() {
      this.playing = false;
      Tone.Transport.pause();
      this.drums.stop();
    },

    togglePlayback() {
      if (this.playing) {
        this.stop();
      } else {
        this.start();
      }
    },

    toggleNote(note) {
      note.toggle();
      if (note.on && Tone.Transport.state !== 'started') {
        this.drums.playNote(note);
      }
    },

    playNote(note) {
      this.drums.playNote(note);
    },

    toggleHeader(control) {
      if (this.header === control) {
        this.header = 'none';
      } else {
        this.header = control;
      }
    },

    toggleLane(lane) {
      lane.toggle();
      this.drums.toggleLane(lane);
    },

    onBeatChange(beat) {
      const { href, hash } = beat.getUrl();
      this.beatUrl = href;
      location.hash = hash;
      storage.setLastOpenBeat(beat);
    },

    onTitleChange(title) {
      document.title = title + 'LearningMusic';
    },

    saveBeat() {
      storage.saveBeat(this.beat);
      this.savedBeatTitles = storage.getSavedBeatTitles();
      this.$refs.feedbackButton.setLabel('Saved!');
    },

    loadSavedBeat(title) {
      this.stop();
      this.loading = true;
      this.beat = storage.loadBeat(title, this.beat);
      this.drums = new Drums(this.beat.lanes, this.beat.samplePack, () => {
        this.loading = false;
      });
    },

    clearPattern() {
      this.stop();
      this.beat.clear();
    },
  },
};
</script>

<style>
@import './assets/tailwind.css';
@import './assets/custom.css';

body {
  color: rgba(0, 0, 0, 0.87);
  @apply text-base py-16 px-16;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
</style>
