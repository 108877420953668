<template>
  <button
    @click="$emit('click', $event)"
    :class="
      `cursor-pointer px-4 py-1 ${
        color === 'primary'
          ? toggled
            ? 'bg-black-400 active:bg-black-400 text-white'
            : 'bg-black-200 active:bg-black-400 text-white'
          : toggled
          ? 'bg-gray-600 active:bg-gray-600 text-white'
          : 'bg-gray-700 active:bg-gray-600 text-white'
      }`
    "
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    color: String,
    toggled: Boolean,
  },
};
</script>
