<template>
  <div class="flex pr-5 w-full rounded-br">
    <div
      class="w-44 h-12 inline-flex items-center cursor-default px-1 text-white"
    >
      <span
        class="text-right w-full"
        style="font-weight: 500; color: #ecf6fe; font-family: 'Work Sans', sans-serif; font-size: 16px;"
        >Tempo</span
      >
    </div>
    <div class="ml-2 flex-grow inline-flex items-center range-slider-wrap">
      <RangeSlider
        :value="value"
        @input="$emit('input', $event)"
        :min="30"
        :max="240"
      />
    </div>
    <div
      class="w-16 h-12 inline-flex items-center cursor-default px-1 text-white"
    >
      <span
        class="text-right w-full"
        style="font-weight: 500; color: #e71548; font-family: 'Work Sans', sans-serif; font-size: 24px;"
        >{{ value }}</span
      >
    </div>
  </div>
</template>

<script>
import RangeSlider from './RangeSlider.vue';

export default {
  name: 'tempo-control',

  components: {
    RangeSlider,
  },

  props: {
    value: Number,
  },
};
</script>
