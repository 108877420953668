<template>
  <div class="flex " :class="`${offset === 0 ? '' : ''}`">
    <button
      class="w-56 h-12 inline-flex items-center cursor-pointer capitalize text-gray-400 btn-beat-text"
      :class="`${offset === 0 ? 'btn-beat-top' : ''}`"
      @mousedown="playNote"
      @keydown.space="playNote"
    >
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="24.000000pt"
        height="24.000000pt"
        viewBox="0 0 24.000000 24.000000"
        preserveAspectRatio="xMidYMid meet"
        style="width: 14px; height: 14px; margin-right: 10px;"
      >
        <g
          transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
          fill="#cbd5e0"
          stroke="none"
        >
          <path
            d="M87 192 c-50 -51 -51 -95 -4 -140 52 -50 57 -45 57 67 0 55 -4 102 -8 105 -5 3 -25 -12 -45 -32z"
          />
          <path
            d="M160 193 c30 -11 60 -47 60 -73 0 -26 -30 -62 -60 -73 -12 -4 -11 -6 3 -6 29 -1 67 44 67 79 0 35 -38 80 -67 79 -14 0 -15 -2 -3 -6z"
          />
          <path
            d="M168 154 c12 -8 22 -24 22 -34 0 -10 -10 -26 -22 -34 -19 -13 -20 -16 -5 -16 26 0 43 44 29 75 -6 14 -19 25 -29 25 -15 0 -14 -3 5 -16z"
          />
          <path
            d="M10 120 c0 -29 4 -40 15 -40 11 0 15 11 15 40 0 29 -4 40 -15 40 -11 0 -15 -11 -15 -40z"
          />
          <path
            d="M154 120 c0 -11 4 -20 8 -20 4 0 8 9 8 20 0 11 -4 20 -8 20 -4 0 -8 -9 -8 -20z"
          />
        </g>
      </svg>
      {{ lane.prettyName }}
    </button>
    <Note
      v-for="(note, index) in lane.notes"
      :key="index"
      :note="note"
      @toggle="toggleNote"
    />
    <div class="h-12 w-40 text-gray-400 btn-beat-text2">
      <div class="h-8" style="width: 80%;">
        <RangeSlider :min="30" :max="240" :step="1" />
      </div>
    </div>
  </div>
</template>

<script>
import Note from './Note.vue';
import RangeSlider from './RangeSlider';

export default {
  name: 'Lane',

  components: { Note, RangeSlider },

  props: {
    lane: Object,
    offset: Number,
  },

  methods: {
    toggleNote(note) {
      this.$emit('toggle-note', note);
    },

    playNote() {
      this.$emit('play-note', {
        lane: this.lane.name,
        offset: 0,
        on: true,
      });
    },
  },
};
</script>
